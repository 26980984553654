.picker-container {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
}

.select-container {
  --height: 200px;
  overflow-y: scroll;
  scrollbar-width: none;
  height: var(--height);
  width: 50%;
  scroll-behavior: smooth;
  /* padding should be ( height - item height )/2 
		currently height is 200px and item height is 24px for
		the line height plus 7px on each side for the padding, so 38px*/
  padding: calc((var(--height) - 38px) / 2) 0;
}

.select-container::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0px;
  background: transparent;
}

.select-container .select-item {
  padding: 7px;
  /* border: 1px solid #ccc; */
  /* margin-bottom: 10px; */
  margin: 0;
}

.picker-container .select-bar {
  position: absolute;
  top: calc(50% - 15px);

  width: 100%;
  height: 30px;
  background-color: var(--accent);
  opacity: 0.3;
  pointer-events: none;
}

div.dashboard-content {
  background-color: var(--background);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 340px;

  margin-bottom: 20px;
}

div.dashboard-content .big-screen {
  display: none;
}

@media (width >= 768px) {
  div.dashboard-content {
    width: 700px;
  }

  div.dashboard-content .big-screen {
    display: inline-block;
  }

  div.dashboard-content li.big-screen {
    display: flex;
  }

  div.dashboard-content li.big-screen span {
    font-weight: bold;
  }

  div.dashboard-content .employee-daily-hours {
    text-align: center;
    background-color: inherit;
    border: none;
    border-radius: 0;
  }

  input.employee-daily-hours::-webkit-inner-spin-button,
  input.employee-daily-hours::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  div.dashboard-content .save-button {
    transition: opacity 0.3s;
  }

  div.dashboard-content .save-button:disabled {
    opacity: 0;
  }

  div.dashboard-content .employee-weekly-hours {
    text-align: center;
    /* margin-left: 3ch; */
    margin-left: 1ch;
    /* padding-right: 1ch; */
    width: 10ch;
  }

  div.dashboard-content span.employee-name {
    min-width: 20ch;
    width: max-content;
    margin-right: auto;
  }

  div.dashboard-content .dropdown {
    width: 1ch;
  }

  div.dashboard-content .admin-button-container button.print-table {
    right: 28px;
  }
}

.pdfPreviewContainer,
#pdfobject {
  height: 90vh;
  width: 90vw;
}

.dashboard-content .admin-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}

.dashboard-content .admin-button-container button.add-emp {
  color: var(--background);
  background-color: var(--accent);

  /* width: 50%; */
  padding: 5px 7px;
  margin-top: 20px;

  border-radius: 3px;
  text-align: center;
  transition: all 0.2s;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dashboard-content .admin-button-container button.add-emp:hover {
  background-color: var(--text);
}

.dashboard-content .admin-button-container button.print-table {
  position: absolute;
  top: 1px;
  right: 1px;
  transition: all 0.2s;
  /* padding: 10px; */
  border-radius: 3px;
  box-sizing: border-box;
  color: var(--background);
  background-color: var(--text);
}

.dashboard-content .admin-button-container button.print-table:hover {
  cursor: pointer;
  /* background-color: var(--backgroundThree); */
  background-color: var(--accent);
}

.dashboard-content .admin-button-container button.add-emp svg {
  padding-right: 5px;
  font-size: 20px;
}

.dashboard-content.skeleton {
  padding: 0;
}

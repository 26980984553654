.DayPicker {
  width: 100%;
}

.carouselWrapper {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  scrollbar-width: none; /* Firefox */

  -webkit-user-select: none; /* Safari */
  user-select: none;
}

.carouselWrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.dateCarousel {
  border-collapse: collapse;
  white-space: nowrap;
  min-width: 100%;
  transition: all 0.5s;
  position: right;
}

.date {
  background-color: var(--backgroundThree);
  border: 2px solid black;
  min-width: 80px;
  transition: all 0.25s;
  position: relative;
}

.selected {
  background-color: var(--accent);
  border-bottom-color: var(--accent);
  border-right-color: black;
  font-weight: bold;
  color: var(--background);
  /* transform: translateY(10%) scaleY(120%); */
  z-index: 10;
}

.dateDay {
  font-size: 12px;
  padding: 0;
  margin: 0;

  padding-top: 5px;
}

.dateNum {
  font-size: 24px;
  padding: 5px;
  margin: 0;
}

.dateHours {
  font-size: 12px;
  padding: 0;
  margin: 0;
  padding-bottom: 5px;
  /* text-align: left;
	padding-left: 10px; */
}

.statusCircle {
  position: absolute;
  width: 0;
  height: 0;
  /* border: none; */
  height: 10px;
  width: 10px;
  border-radius: 0 0 0 100%;

  /* border: 10px solid red; */
  /* border-bottom-color: clear; */

  /* background-color: red; */
  /* border-radius: 50%; */
  top: 0px;
  right: 0px;
}

.badHours {
  background-color: var(--secondary);
}

/* .goodHours {
	background-color: var(--primary);
} */

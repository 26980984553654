details.data-transfer-details {
  margin-top: 3rem;
  padding-top: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

form.data-transfer-form {
  padding-top: 1rem;
}

.data-transfer-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.data-transfer-button {
  width: 100%;
  padding: 10px;
  background-color: var(--secondary);
  color: var(--background);
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.data-transfer-button:hover {
  background-color: var(--primary);
}

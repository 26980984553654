@import url("https://fonts.googleapis.com/css?family=Lobster+Two");

:root {
  --text: #0e1b08;
  --background: #f7fcf5;
  --backgroundTwo: #f7f7f7;
  --backgroundThree: #f1f1f1;
  --primary: #54bc55;
  --secondary: #9dd7d9;
  --accent: #76a8c9;

  --titleFont: "Lobster Two";
}

/* dark theme */
/* :root {
	--background: #0e1b08;
	--backgroundTwo: #1f1f1f;
	--backgroundTwo: #2f2f2f;
	--text: #f7fcf5;
	--primary: #54bc55;
	--secondary: #9dd7d9;
	--accent: #76a8c9;

	--titleFont:"Lobster Two"
}

* {
	background-color: var(--background);
	color: var(--text)
} */

button {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: none;
  border-radius: 3px;
}

.loading-page {
  width: 100%;
  height: 100%;
  background-color: green;
}

/* Dashboard.css */
.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  background-color: var(--backgroundTwo);
  position: relative;

  /* this serves to push the hour adder down some, since it was actually weird for it to be dead center */
  /* also, that's how tall the header is (below) */
  padding-top: 75px;
  padding-bottom: 30px;
  box-sizing: border-box; /* this prevents that ^ from forcing the window to be scroll-able */
}

.dashboard-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0px;
}

.dashboard-logout {
  background-color: var(--primary);
  color: var(--background);
  border: none;
  border-radius: 3px;
  padding: 8px 12px;
  cursor: pointer;
}

.presets {
  margin-top: 10px;
}

.dashboard-header > h1 {
  font-family: var(--titleFont);
  color: var(--primary);
  margin-top: 10px;
}

h2 {
  font-size: 50px;
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
}

h2 > span.title {
  font-family: var(--titleFont);
  display: inline-block;
  color: var(--primary);
}

.dashboard-container .tagline {
  margin: 0;
  margin-bottom: 80px;
}

#claimCode {
  font-size: 40px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* border: none; */
  text-transform: uppercase;
}

#claimCode > input.claim-code {
  font-size: 40px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: 1px solid var(--text);
  border-radius: 5px;
  text-transform: uppercase;
  margin: 10px 6px 10px 6px;
  width: 40px;
  text-align: center;
}

.claim-code-info-p {
  text-align: center;
  margin: 5px;
  margin-bottom: 25px;
}

.claim-code-info-p > span {
  color: var(--accent);
  text-decoration: underline;
  transition: all 0.2s;
}

.claim-code-info-p > span:hover {
  color: var(--secondary);
  text-decoration: none;
  cursor: pointer;
}

.claim-submit-button {
  background-color: var(--primary);
  color: var(--background);
  padding: 10px 15px 10px 15px;
  margin: 0;
}

.info-modal-container {
  width: 320px;
  height: 250;
  padding: 20px;
  margin: 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
}

.info-modal-container > p {
  text-align: center;
}

.info-modal-container > button {
  background-color: var(--accent);
  color: var(--background);
  padding: 10px 15px 10px 15px;
}
